import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import MultiTabsLayout from "../../../components/MultiTabsLayout/MultiTabsLayout";
import { useGetTestExecution } from "../../../api/TestExecutionsApi";
import Error from "../../../components/Error/Error";
import DetailItem from "../../../components/DetailItem";
import Loading from "../../../components/Loading/Loading";
import ExecutionStatus from "./common/ExecutionStatus/ExecutionStatus";
import StatusCard from "../../../components/ResourceStatus/StatusCard";
import type { TestExecution } from "../../../@types/sd/testexecutions";
import { useGetFindings } from "../../../api/FindingsApi";
import type { TrafficDiffFinding } from "../../../@types/sd/findings";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { DiffCat } from "../../../@types/sd/findings.d.ts";
import FlatView from "./views/FlatView";
import GroupedView from "./views/GroupedView/GroupedView";
import type { ControlProps } from "./common/Controls/Controls";
import Controls, { ViewType } from "./common/Controls/Controls";
import { filterFindings } from "./util";

interface TestExecutionDetailProps {
  testName?: string;
  executionName?: string;
}

const TestExecutionDetail: React.FC<TestExecutionDetailProps> = ({
  testName: testNameProp,
  executionName: executionNameProp,
}) => {
  const [controlProps, setControlProps] = useState<ControlProps>({
    showHeaderFindings: true,
    showMetadataFindings: true,
    viewType: ViewType.Grouped,
    filterByCategories: [DiffCat.Red],
  });

  const {
    testName: testNameFromParams,
    executionName: executionNameFromParams,
  } = useParams<{
    testName: string;
    executionName: string;
  }>();

  const testName = testNameProp || testNameFromParams;
  const executionName = executionNameProp || executionNameFromParams;

  const navigate = useNavigate();

  const executionDetail = useGetTestExecution(testName!, executionName!);
  const findingsCall = useGetFindings(testName!, executionName!, 0);
  const findings: TrafficDiffFinding[] = useMemo(() => {
    if (!findingsCall.data || !Array.isArray(findingsCall.data.findings))
      return [] as TrafficDiffFinding[];
    return findingsCall.data.findings.sort((a, b) => b.relevance - a.relevance);
  }, [findingsCall.data, findingsCall.status]);

  const filteredFindings = useMemo(
    () => filterFindings(findings, controlProps),
    [findings, controlProps]
  );

  const errorMessage = React.useMemo(() => {
    if (!executionDetail.data) return null;

    const status = executionDetail.data.status;
    if (status.phase !== "failed") {
      return null;
    }
    if (!status.finalState?.failed) {
      return null;
    }

    return status.finalState.failed.message;
  }, [executionDetail]);

  if (executionDetail.error) {
    // TODO: Distinguish between not found error and other errors for user messaging.
    // Requires an update to have the formattedSandboxData endpoint return a 404.
    if (executionDetail.error.response?.status === 400) {
      // Response isn't set for timeouts, DNS resolution failures, server crash etc. Hence, the optional check above.
      navigate("/testing/tests");
      return null;
    }
    return (
      <Error
        text={`Error: ${
          executionDetail.error.message || "Unknown error fetching the test"
        }`}
      />
    );
  }

  if (executionDetail.isLoading || !executionDetail.data) {
    return <Loading />;
  }

  const breadCrumbs = [
    {
      text: "Tests",
      onClick: () => navigate("/testing/tests"),
    },
    {
      text: `Test: ${testName}`,
      onClick: () => navigate(`/testing/tests/${testName}`),
    },
    {
      text: `Execution: ${executionName}`,
    },
  ];

  if (!executionDetail.data) {
    return null;
  }
  const executionData = executionDetail.data as TestExecution;
  const triggeredBy = executionData.status.triggeredBy;
  return (
    <MultiTabsLayout
      tabs={[]}
      breadcrumbs={breadCrumbs}
      title={executionDetail.data?.name || ""}
      status={<ExecutionStatus status={executionData.status.phase} />}
    >
      {errorMessage && (
        <div className="mb-5">
          <StatusCard
            title="Job Failed"
            description={errorMessage}
            intent={Intent.DANGER}
          />
        </div>
      )}
      <div className="mb-8">
        <DetailItem title="Test" value={executionData.spec.test} />
      </div>
      {triggeredBy && (
        <div className="mb-4">
          <DetailItem title="Triggered by" value="" />
          <p>Sandbox: {triggeredBy.sandbox}</p>
          <p>Workload: {triggeredBy.trigger.sandboxOf.name}</p>
          <p>Cluster: {triggeredBy.trigger.executionTemplate.cluster}</p>
        </div>
      )}
      <Controls
        initialValue={controlProps}
        onChange={(cp) => setControlProps(cp)}
      />
      {controlProps.viewType === ViewType.Flat ? (
        <FlatView findings={filteredFindings} />
      ) : (
        <GroupedView findings={filteredFindings} testName={testName!} />
      )}
    </MultiTabsLayout>
  );
};

export default TestExecutionDetail;
