// eslint-disable-next-line import/prefer-default-export
import type { TestTrigger } from "../../../../../../@types/sd/testspec";

// eslint-disable-next-line import/prefer-default-export
export const BASE_EMPTY_TRIGGER: TestTrigger = {
  id: "",
  executionTemplate: {
    autodiff: {
      enabled: true,
    },
    cluster: "",
  },
  sandboxOf: {
    name: "",
    kind: "Deployment",
    namespace: "",
  },
};
