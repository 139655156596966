/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */
import React, { useState } from "react";
import style from "./FilteredView.module.css";
import type { FilterOptions } from "./Filter";
import Filters from "./Filters";
import usePagination from "../../hooks/UsePagination";
import ButtonGroup from "../PaginatedTable/ButtonGroup";

export type FilteredViewColumn<T> = {
  id: string;
  render: (value: T) => React.ReactNode;

  // Number of spaces to be used
  weight: number;
};

type FilteredViewProps<T> = {
  title: React.ReactNode | ((filteredCount: number) => React.ReactNode);
  data: T[];
  columns: FilteredViewColumn<T>[];
  filters?: FilterOptions<T>[];

  // This means the total of columns created by the grid
  totalWeight: number;

  // Pagination
  pageSize?: number;

  // Callbacks
  onRowClick?: (row: T) => void;
};

type FilterViewRowBase = {
  id: string | number;
};

const FilteredView = <T extends FilterViewRowBase>({
  title,
  data,
  filters,
  columns,
  totalWeight,
  pageSize,
  onRowClick,
}: FilteredViewProps<T>) => {
  const [cleanedData, setCleanedData] = useState(data);

  const {
    currentPage,
    onPageChange,
    totalPages,
    data: paginatedData,
  } = usePagination<T>({ data, pageSize });

  return (
    <div>
      <div className={style.view}>
        {Array.isArray(filters) && filters.length > 0 && (
          <div className={style.view_sidebar}>
            <Filters
              filters={filters}
              data={data}
              onChange={(newData) => {
                setCleanedData(newData);
              }}
            />
          </div>
        )}

        <div className={style.view_body}>
          <div className={style.view_header}>
            <p>
              {typeof title === "function" ? title(cleanedData.length) : title}
            </p>
          </div>
          {paginatedData.map((row) => (
            <div
              key={`row-${row.id}`}
              style={{ gridTemplateColumns: `repeat(${totalWeight}, 1fr)` }}
              onClick={() => onRowClick?.(row)}
              className={style.row}
            >
              {columns.map((col) => (
                <div
                  className={style.column}
                  key={`col-${row.id}${String(col.id)}`}
                  style={{ gridColumn: `span ${col.weight}` }}
                >
                  {col.render(row)}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {totalPages > 1 && (
        <ButtonGroup
          pageCount={totalPages}
          onPageChange={onPageChange}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default FilteredView;
