import React from "react";
import type { NavigateFunction } from "react-router/dist/lib/hooks";
import type { QueryResult } from "../../../../@types/sd/testexecutions";
import { getTimeAgoElement } from "../../../../util/Util";
import ActionLink from "../../../../components/theming/ActionLink";

export const columns = [
  {
    Header: "Test",
    accessor: "testName",
    search: true,
  },
  {
    Header: "Execution",
    accessor: "executionName",
    search: true,
  },
  {
    Header: "Triggered for Workload",
    accessor: "triggeredBy",
    search: true,
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Started At",
    accessor: "startedAt",
    search: true,
  },
  {
    Header: "Finished At",
    accessor: "finishedAt",
    search: true,
  },
];

export interface FormattedExecutionData {
  testName: string;
  executionName: string;
  triggeredBy: {
    text: string;
    component: React.ReactNode;
  };
  status: {
    text: string;
    component: React.ReactNode;
  };
  startedAt: {
    text: string;
    component: React.ReactNode;
  };
  finishedAt: {
    text: string;
    component: React.ReactNode;
  };
}

export const formatData = (
  data: QueryResult[] = []
): FormattedExecutionData[] =>
  data.map((queryResult) => {
    const { execution } = queryResult;
    return {
      testName: execution.spec.test,
      executionName: execution.name,
      triggeredBy: {
        text: execution.status.triggeredBy?.trigger.sandboxOf.name,
        component: (
          <div>{execution.status.triggeredBy?.trigger.sandboxOf.name}</div>
        ),
      },
      status: {
        text: execution.status.phase,
        component: <div>{execution.status.phase}</div>,
      },
      startedAt: {
        text: execution.status.startedAt,
        component: <div>{getTimeAgoElement(execution.status.startedAt)}</div>,
      },
      finishedAt: {
        text: execution.status.finishedAt,
        component: <div>{getTimeAgoElement(execution.status.finishedAt)}</div>,
      },
    } as FormattedExecutionData;
  });
