import type { FindingDiffOp } from "../../../../@types/sd/findings";

export interface StructuredDiffOps {
  request: {
    header: FindingDiffOp[];
    body: FindingDiffOp[];
    metadata: FindingDiffOp[];
  };
  response: {
    header: FindingDiffOp[];
    body: FindingDiffOp[];
    metadata: FindingDiffOp[];
  };
}

export const restructureDiffs = (diffs: FindingDiffOp[]): StructuredDiffOps => {
  const structuredDiffs: StructuredDiffOps = {
    request: {
      header: [],
      body: [],
      metadata: [],
    },
    response: {
      header: [],
      body: [],
      metadata: [],
    },
  };

  if (diffs.length === 0) return structuredDiffs;

  const paths = {
    "/request/message/headers": structuredDiffs.request.header,
    "/request/message/body": structuredDiffs.request.body,
    "/request/message": structuredDiffs.request.metadata,
    "/response/message/headers": structuredDiffs.response.header,
    "/response/message/body": structuredDiffs.response.body,
    "/response/message": structuredDiffs.response.metadata,
  };

  diffs.forEach((diff) => {
    Object.entries(paths).forEach(([pathPrefix]) => {
      if (diff.path.startsWith(pathPrefix)) {
        // Directly amend and push to the target array
        paths[pathPrefix].push({
          ...diff,
          path: diff.path.replace(pathPrefix, ""),
        });
      }
    });
  });

  return structuredDiffs;
};
