import { Card, InputGroup } from "@blueprintjs/core";
import React from "react";
import style from "./Configuration.module.css";
import { SdHeading2 } from "../../../../../../components/theming/SdHeading";
import TriggerInput from "../TriggerInput";
import SdButton from "../../../../../../components/theming/SdButton";
import { BASE_EMPTY_TRIGGER } from "./constants";
import type {
  BaseTestSpec,
  TestTrigger,
} from "../../../../../../@types/sd/testspec";

import ValidationError from "../ValidationError/ValidationError";

type ConfigurationProps = {
  testName?: string;
  testSpec?: Partial<BaseTestSpec>;
  errors: Map<string | number, string>;
  onTestNameChange: (name: string) => void;
  onAddTrigger: (trigger: TestTrigger) => void;
  onRemoveTrigger: (idx: number) => void;
  onTriggerChange: (idx: number, trigger: TestTrigger) => void;
};

const Configuration = ({
  testName,
  testSpec,
  errors,
  onTestNameChange,
  onAddTrigger,
  onRemoveTrigger,
  onTriggerChange,
}: ConfigurationProps) => (
  <Card className={style.configuration}>
    <SdHeading2 small lightBackground>
      Configuration
    </SdHeading2>
    <div className={style.fields}>
      <div className={style.field}>
        <p className={style.field_title}>Basic</p>
        <InputGroup
          placeholder="Test Name"
          fill
          value={testName}
          onChange={(e) => {
            onTestNameChange(e.target.value);
          }}
        />
        <ValidationError errorMessage={errors.get("name")} />
      </div>
      <div className={style.field}>
        <p className={style.field_title}>Triggers</p>
        <p className={style.field_description}>
          Run when sandbox is updated / created that contains:
        </p>

        <div className={style.triggers}>
          {testSpec?.triggers?.map((trigger, id) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`trigger-${id}`}>
              <div className="border-b-2 pb-4 mb-5 ">
                <div className="flex mb-4">
                  <TriggerInput
                    trigger={trigger}
                    onChange={(newTrigger) => onTriggerChange(id, newTrigger)}
                  />
                  <SdButton
                    className="ml-4 h-auto"
                    disabled={testSpec?.triggers?.length === 0}
                    icon="minus"
                    onClick={() => {
                      onRemoveTrigger(id);
                    }}
                  />
                </div>
                <ValidationError errorMessage={errors.get(id)} />
              </div>
            </React.Fragment>
          ))}
        </div>

        <SdButton
          icon="plus"
          onClick={() => {
            onAddTrigger(BASE_EMPTY_TRIGGER);
          }}
        >
          Add
        </SdButton>
      </div>
    </div>
  </Card>
);

export default Configuration;
