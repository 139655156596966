import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Icon, Intent, OverlayToaster } from "@blueprintjs/core";
import type { ImperativePanelHandle } from "react-resizable-panels";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useQueryClient } from "react-query";
import MultiTabsLayout from "../../../../components/MultiTabsLayout/MultiTabsLayout";
import BaseEditor from "../../../../components/Editor";
import SdButton from "../../../../components/theming/SdButton";
import type { BaseTestSpec, Test } from "../../../../@types/sd/testspec";
import { useGetTest, useSaveTest } from "../../../../api/TestsApi";
import Loading from "../../../../components/Loading/Loading";
import Error from "../../../../components/Error/Error";
import { validateTest } from "./validate";
import DeleteTestSpecAlert from "./DeleteTestSpecAlert";
import useDisclosure from "../../../../hooks/UseDisclosure";
import {
  useCreateTestExecution,
  useGetTestExecution,
} from "../../../../api/TestExecutionsApi";
import ClusterSelector from "./components/ClusterSelector/ClusterSelector";
import { JOBS_API, useGetJob } from "../../../../api/JobsApi";
import useGetJobLogs from "../../../../hooks/UseGetJobLogs";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { AttemptPhaseEnum } from "../../../../@types/sd/job.d.ts";
import { Configuration, LogPanel } from "./components";
import { testTriggerUpdater } from "./util";
import {
  LEFT_PANEL_MIN_SIZE,
  LOGS_PANEL_COLLAPSED_SIZE,
  LOGS_PANEL_DEFAULT_SIZE,
  LOGS_PANEL_MAX_SIZE,
  LOGS_PANEL_MIN_SIZE,
  MAIN_PANEL_NAME,
} from "./constant";

import style from "./TestSpecEditor.module.css";

const toaster = OverlayToaster.create();

const TestSpecEditor: React.FC = () => {
  const navigate = useNavigate();
  const [test, setTest] = useState<Partial<Test>>({});
  const [validationFormResult, setValidationFormResult] =
    useState<ReturnType<typeof validateTest>>();
  const [clusterNameTest, setClusterNameTest] = useState<string | undefined>();
  const [executionName, setExecutionName] = useState<string>();
  const [targetJobName, setTargetJobName] = useState<string>();

  const [logsMessage, setLogsMessage] = useState<string>("No test started");
  const logPanelRef = useRef<ImperativePanelHandle>(null);

  const queryClient = useQueryClient();

  const deleteModal = useDisclosure(false);
  const showLogPanelContent = useDisclosure(false);

  const { testName: testNameParam } = useParams<{ testName: string }>();

  const isExistingTest = testNameParam !== "editor";

  const { isLoading, error, data } = useGetTest(testNameParam!, {
    enabled: isExistingTest,
  });

  const testExecutionApi = useGetTestExecution(
    test?.name ?? "",
    executionName!,
    {
      enabled: test?.name !== undefined && executionName !== undefined,
    }
  );
  const jobDetailApi = useGetJob(targetJobName!, {
    enabled: targetJobName !== undefined,
  });

  const { logs, isStreamingStopped, stopStreaming } = useGetJobLogs({
    job: jobDetailApi.data,
  });
  const saveTestSpecApi = useSaveTest(
    () => {
      toaster.show({
        message: isExistingTest ? "Test updated" : "Test created",
        intent: Intent.SUCCESS,
      });
      navigate(`/testing/tests/${test?.name}`);
    },
    (apiError) => {
      toaster.show({
        message: apiError.response.data.error,
        intent: Intent.DANGER,
      });
    }
  );
  const createTestExecutionApi = useCreateTestExecution(
    (response) => {
      toaster.show({
        message: "Test job created",
        intent: Intent.SUCCESS,
      });
      setExecutionName(response.name);
      setLogsMessage("Test started. Wait for test job is created...");
    },
    (apiError) => {
      setLogsMessage("Test no started. An error occurred");
      toaster.show({
        message: apiError.response.data.error,
        intent: Intent.DANGER,
      });
    }
  );

  useEffect(() => {
    if (!data) return;
    setTest(data);
  }, [data]);

  useEffect(() => {
    if (!testExecutionApi.isSuccess) return;

    const status = testExecutionApi.data.status;
    setLogsMessage(`Test execution in ${status.phase} state`);

    if (status.phase === "pending") return;

    if (status.phase === "failed" && status.finalState?.failed?.message) {
      setLogsMessage(
        `Test execution failed with ${status.finalState?.failed?.message}`
      );
      return;
    }

    setLogsMessage(`Test execution running with ${status.targetJob}`);
    setTargetJobName(status.targetJob);
    showLogPanelContent.open();
  }, [testExecutionApi.data]);

  useEffect(() => {
    logPanelRef.current?.resize(showLogPanelContent.isOpen ? 20 : 10);
  }, [showLogPanelContent.isOpen]);

  useEffect(() => {
    if (!jobDetailApi.isSuccess || jobDetailApi.isRefetching) return;
    if (
      jobDetailApi.data.status.attempts[0].phase === AttemptPhaseEnum.QUEUED
    ) {
      setLogsMessage(`Waiting job ${targetJobName} to start`);
      stopStreaming(false);
      return;
    }

    if (isStreamingStopped) return;

    const header = `Showing logs for job ${targetJobName}\n`;
    setLogsMessage(header + logs.map((l) => l.message).join(""));
  }, [jobDetailApi, logs, targetJobName, isStreamingStopped]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    // TODO: Distinguish between not found error and other errors for user messaging.
    // Requires an update to have the formattedSandboxData endpoint return a 404.
    if (error.response?.status === 400) {
      // Response isn't set for timeouts, DNS resolution failures, server crash etc. Hence, the optional check above.
      navigate("/testing/tests");
      return null;
    }
    return (
      <Error
        text={`Error: ${error.message || "Unknown error fetching the test"}`}
      />
    );
  }

  const onSave = () => {
    const validationResult = validateTest(test ?? {});
    setValidationFormResult(validationResult);

    const firstError = validationResult.getFirstError();
    if (firstError) {
      toaster.show(firstError);
      return;
    }

    saveTestSpecApi.mutate({
      url: `/api/v2/orgs/:orgName/tests/${test?.name}`,
      data: test?.spec as BaseTestSpec,
    });
  };

  const handleRunTest = () => {
    jobDetailApi.remove();
    testExecutionApi.remove();

    queryClient.invalidateQueries([JOBS_API, targetJobName]);

    stopStreaming(true);
    setLogsMessage("Starting test");
    createTestExecutionApi.mutate({
      url: `/api/v2/orgs/:orgName/tests/${test?.name}/executions`,
      data: {
        executionContext: {
          cluster: clusterNameTest!,
        },
      },
    });
  };

  const updateTrigger = testTriggerUpdater(setTest);

  const breadcrumbs = [
    {
      text: "Tests",
      onClick: () => navigate("/testing/tests"),
    },
    { text: "Editor" },
  ];
  return (
    <MultiTabsLayout
      title={test?.name ?? "New test"}
      tabs={[]}
      breadcrumbs={breadcrumbs}
      actions={[
        {
          label: "Delete",
          onClick: deleteModal.open,
          display: isExistingTest,
          isRiskAction: true,
          icon: "trash",
        },
      ]}
    >
      <div className={style.wrapper}>
        <DeleteTestSpecAlert
          isDeleteModalOpen={deleteModal.isOpen}
          name={test?.name ?? ""}
          onCancel={deleteModal.close}
        />
        <PanelGroup
          direction="horizontal"
          className={style.wrapper}
          autoSaveId={MAIN_PANEL_NAME}
        >
          <Panel order={1} minSize={LEFT_PANEL_MIN_SIZE}>
            <PanelGroup direction="vertical">
              <Panel order={1}>
                <BaseEditor
                  language="python"
                  value={test?.spec?.script}
                  onChange={(script) =>
                    setTest((t) => ({
                      ...t,
                      spec: {
                        ...t.spec,
                        script: script || "",
                      },
                    }))
                  }
                  header={
                    <div className="flex gap-1 justify-end items-end">
                      <ClusterSelector
                        value={clusterNameTest}
                        onChange={setClusterNameTest}
                        unsetInitial
                      />
                      <SdButton
                        icon={<Icon icon="bug" color="white" />}
                        onClick={handleRunTest}
                        disabled={!clusterNameTest}
                      >
                        Test
                      </SdButton>
                    </div>
                  }
                />
              </Panel>
              <PanelResizeHandle className="w-full h-2" />

              <Panel
                order={2}
                maxSize={LOGS_PANEL_MAX_SIZE}
                minSize={LOGS_PANEL_MIN_SIZE}
                collapsedSize={LOGS_PANEL_COLLAPSED_SIZE}
                defaultSize={LOGS_PANEL_DEFAULT_SIZE}
                collapsible
                onCollapse={showLogPanelContent.close}
                onExpand={showLogPanelContent.open}
                ref={logPanelRef}
              >
                <LogPanel
                  isCollapsed={!showLogPanelContent.isOpen}
                  onOpen={() => {
                    showLogPanelContent.open();
                    logPanelRef.current?.resize(20);
                  }}
                  logsMessage={logsMessage}
                />
              </Panel>
            </PanelGroup>
          </Panel>

          <PanelResizeHandle className="w-2" />
          <Panel order={2} minSize={12} maxSize={30}>
            <Configuration
              testName={test?.name}
              testSpec={test?.spec}
              errors={validationFormResult?.errors ?? new Map()}
              onTestNameChange={(name) => setTest((t) => ({ ...t, name }))}
              onAddTrigger={(newTrigger) => {
                setValidationFormResult(undefined);

                setTest((t) => ({
                  ...t,
                  spec: {
                    ...(t?.spec ?? {}),
                    script: t.spec?.script ?? "",
                    triggers: [...(t?.spec?.triggers ?? []), newTrigger],
                  },
                }));
              }}
              onRemoveTrigger={(triggerID) => {
                setTest((t) => ({
                  ...t,
                  spec: {
                    ...(t?.spec ?? {}),
                    script: t.spec?.script ?? "",
                    triggers: t.spec?.triggers?.filter(
                      (_, i) => i !== triggerID
                    ),
                  },
                }));
              }}
              onTriggerChange={updateTrigger}
            />
          </Panel>
        </PanelGroup>
        <SdButton onClick={onSave} className="mt-10">
          Save
        </SdButton>
      </div>
    </MultiTabsLayout>
  );
};

export default TestSpecEditor;
