import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Tag } from "@blueprintjs/core";
import { TbServerBolt } from "react-icons/tb";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import styles from "./ListTests.module.css";
import { filterTestSpecs } from "../utils";
import SdInput from "../../../../components/theming/SdInput";
import SdButton from "../../../../components/theming/SdButton";
import CreateFirst from "../../../../components/CreateFirst";
import { useGetTests } from "../../../../api/TestsApi";

interface Props {
  onSelected: (testName: string) => void;
}

const ListTests: React.FC<Props> = ({ onSelected }) => {
  const [filterText, setFilterText] = React.useState<string>("");
  const [selectedTest, setSelectedTest] = React.useState<string | undefined>(
    undefined
  );
  const { isSuccess, data } = useGetTests();
  const filteredList = React.useMemo(
    () => filterTestSpecs(filterText, data),
    [data, isSuccess, filterText]
  );

  React.useEffect(() => {
    if (!selectedTest && data && data.length > 0) {
      setSelectedTest(data[0].name);
    }
  }, [data]);

  React.useEffect(() => {
    if (selectedTest) {
      onSelected(selectedTest);
    }
  }, [selectedTest]);

  const navigate = useNavigate();
  if (!isSuccess) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.heading}>Tests</div>
        <SdButton
          onClick={() => {
            navigate(`/testing/tests/editor`);
          }}
          icon="plus"
        >
          New Test
        </SdButton>
      </div>
      {data.length > 0 ? (
        <>
          <div>
            <SdInput
              name="search-field"
              type="text"
              placeholder="Type to search ..."
              className="bp4-input bp4-fill bp4-medium mb-4 mt-10"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
          <div className={styles.catalog}>
            {filteredList.map((entry) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <Card
                key={entry.name}
                className={`${styles.entry} ${
                  selectedTest === entry.name ? styles.selected : ""
                }`}
                onClick={() => setSelectedTest(entry.name)}
              >
                <>
                  <div className={styles.title}>
                    <div>{entry.name}</div>
                    <div>
                      <SdButton
                        onClick={() => navigate(`/testing/tests/${entry.name}`)}
                        className={styles.editBtn}
                        minimal
                      >
                        Edit
                      </SdButton>
                    </div>
                  </div>
                  {entry.spec.triggers?.length ? (
                    <div className={styles.tags}>
                      <div className={styles.tagsTitle}>Triggered by:</div>
                      <div className={styles.tagsList}>
                        {entry.spec.triggers.map((trigger) => (
                          <Tag
                            key={trigger.sandboxOf.name}
                            className={styles.tag}
                          >
                            <Popover2>
                              <Tooltip2
                                content={`Triggered by sandbox in cluster ${
                                  trigger.executionTemplate.cluster
                                } and namespace ${
                                  trigger.sandboxOf.namespace
                                } forking workload named ${
                                  trigger.sandboxOf.name
                                } of type ${trigger.sandboxOf.kind.toLowerCase()}`}
                                intent="none"
                                hoverOpenDelay={250}
                                position="bottom-left"
                              >
                                {trigger.sandboxOf.name}
                              </Tooltip2>
                            </Popover2>
                          </Tag>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <CreateFirst
          icon={<TbServerBolt size={80} />}
          message="No Tests found"
          buttonLabel="Create Test Spec"
          onClick={() => navigate("/testing/tests/editor")}
        />
      )}
    </div>
  );
};

export default ListTests;
