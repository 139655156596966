import React from "react";
import classNames from "classnames";
import styles from "./FindingElement.module.css";
import type { AstKeyword } from "../../DiffViewer/ast";

interface KeywordRendererProps {
  keywords: AstKeyword[];
}

// eslint-disable-next-line import/prefer-default-export
export const KeywordRenderer: React.FC<KeywordRendererProps> = ({
  keywords,
}) => {
  const renderLine = (keyword: AstKeyword) => {
    const renderKey = (text: string) => {
      const keyMatch = text.match(/^(".*?"):/);
      if (keyMatch) {
        const [fullMatch, key] = keyMatch;
        return (
          <>
            <span className={styles.theme_dark_key}>{key}</span>:
            {text.slice(fullMatch.length)}
          </>
        );
      }
      return text;
    };

    if (keyword.op === "highlight") {
      const parts = keyword.line.split(new RegExp(`(${keyword.highlight})`));
      const highlightColor =
        keyword.action === "added"
          ? styles.highlight_added
          : styles.highlight_removed;

      return parts.map((part, index) => {
        if (part === keyword.highlight) {
          return (
            <span
              key={index}
              className={`${styles.fontBold} ${highlightColor}`}
            >
              {part}
            </span>
          );
        }
        return <span key={index}>{renderKey(part)}</span>;
      });
    }

    // Here the op is no-op or symbol
    // TODO: Handle title level
    return renderKey(keyword.line);
  };

  const getLineBackgroundColor = (keyword: AstKeyword) => {
    switch (keyword.op) {
      case "highlight":
        if (keyword.action === "added") {
          return styles.highlight_line_added;
        }

        return styles.highlight_line_removed;
      default:
        return "";
    }
  };

  const getOppositeHighlight = (k: AstKeyword) => {
    const keyword = {
      ...k,
    };

    if (keyword.op !== "highlight") {
      return null;
    }

    keyword.line = keyword.line.replace(
      keyword.highlight,
      keyword.previousLine
    );
    keyword.highlight = keyword.previousLine;
    keyword.action = keyword.action === "added" ? "removed" : "added";

    return (
      <div
        className={`${styles.keywordLine} ${getLineBackgroundColor(keyword)}`}
      >
        <span className={styles.indentation}>
          {"  ".repeat(keyword.identLevel)}
        </span>
        {renderLine(keyword)}
        {keyword.endLine ? "," : ""}
      </div>
    );
  };

  return (
    <pre className={styles.container}>
      {keywords.map((keyword, index) => (
        <>
          {getOppositeHighlight(keyword)}
          <div
            key={index}
            className={`${styles.keywordLine} ${getLineBackgroundColor(
              keyword
            )}`}
          >
            <span className={styles.indentation}>
              {"  ".repeat(keyword.identLevel)}
            </span>
            {renderLine(keyword)}
            {keyword.endLine ? "," : ""}
          </div>
        </>
      ))}
    </pre>
  );
};
