import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { FaArrowLeft } from "react-icons/fa";
import type { SandboxV2 } from "../../../../@types/sd/sandboxv2";
import {
  FILTER_TEST_EXECUTIONS_API_NAME,
  useQuerySandboxTestExecutions,
} from "../../../../api/TestExecutionsApi";
import PaginatedTable from "../../../../components/PaginatedTable/PaginatedTable";
import type { FormattedExecutionData } from "./helper";
import { columns, formatData } from "./helper";
import TestExecutionDetail from "../../../Testing/TestExecutionDetail";
import SdButton from "../../../../components/theming/SdButton";
import style from "./Tests.module.css";

interface Props {
  sandbox: SandboxV2;
}

const Tests: React.FC<Props> = ({ sandbox }) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries(FILTER_TEST_EXECUTIONS_API_NAME);
  }, []);

  const [selectedTestName, setSelectedTestName] = useState<string | null>(null);
  const [selectedExecutionName, setSelectedExecutionName] = useState<
    string | null
  >(null);
  const { isSuccess, isLoading, data } = useQuerySandboxTestExecutions(
    sandbox.name,
    {
      enabled: !!sandbox?.name,
    }
  );
  const isTestExecutionSelected: boolean = React.useMemo(
    () => !!(selectedTestName && selectedExecutionName),
    [selectedTestName, selectedExecutionName]
  );
  if (!isSuccess || isLoading || !data) {
    return null;
  }
  if (data.length === 0) {
    return <div>No test executions found.</div>;
  }
  const formattedData: FormattedExecutionData[] = formatData(data);
  return (
    <div>
      {!isTestExecutionSelected ? (
        <PaginatedTable<FormattedExecutionData>
          columns={columns}
          data={formattedData}
          enableSearch={false}
          onRowClickCallback={(row: FormattedExecutionData) => {
            setSelectedTestName(row.testName);
            setSelectedExecutionName(row.executionName);
          }}
          pageSize={10}
        />
      ) : (
        <div>
          <SdButton
            icon={<FaArrowLeft />}
            className={style.gap}
            onClick={() => {
              setSelectedTestName(null);
              setSelectedExecutionName(null);
            }}
          >
            View all Test Executions
          </SdButton>
          <div className={style.content}>
            <TestExecutionDetail
              testName={selectedTestName!}
              executionName={selectedExecutionName!}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tests;
