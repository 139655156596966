import type { FindingDiffOp } from "../../../../../@types/sd/findings";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { FindingOp } from "../../../../../@types/sd/findings.d.ts";
import { displayValue } from "./util";

type AstKeywordValueType = {
  op: "no-op";
  line: string;
};

type AstSectionTitleType = {
  op: "title";
  line: string;
};

type AstKeywordSymbolType = {
  op: "symbol";
  line: string;
};

type AstKeywordHighlightType = {
  op: "highlight";
  line: string;
  previousLine: string;
  highlight: string;
  action: "added" | "removed";
};

export type AstKeyword = {
  identLevel: number;
  endLine?: string;
} & (
  | AstKeywordValueType
  | AstSectionTitleType
  | AstKeywordHighlightType
  | AstKeywordSymbolType
);

export type AstAlgorithm = "header" | "body" | "metadata";

const generateHeaderAst = (header: FindingDiffOp): AstKeyword => {
  const headerName = header.path.substring(1);
  const line = `${headerName}: ${displayValue(header.value)}`;

  const baseAst: AstKeyword = {
    op: "no-op",
    line,
    identLevel: 0,
  };

  if (header.op === FindingOp.Copy) {
    baseAst.line = `${headerName}: ${displayValue(header.oldValue)}`;

    return baseAst;
  }

  const highlightAst: AstKeyword = {
    ...baseAst,
    op: "highlight",
    previousLine: displayValue(header.oldValue),
    highlight: displayValue(header.value),
    action: "added",
  };

  if (header.op === FindingOp.Remove) {
    highlightAst.action = "removed";
  }

  return highlightAst;
};

const generateHeadersAst = (headersOps: FindingDiffOp[]): AstKeyword[] =>
  headersOps.map(generateHeaderAst);

const generateMetadataAst = (metadataOps: FindingDiffOp[]): AstKeyword[] => {
  const keywords: AstKeyword[] = [];

  metadataOps.forEach((op) => {
    if (op.path.split("/").length > 2) return;

    keywords.push(generateHeaderAst(op));
  });

  return keywords;
};

export const generateAstDiff = (
  diffOps: FindingDiffOp[],
  astAlgorithm: AstAlgorithm
): AstKeyword[] => {
  // TODO: Generate AST for body and message
  switch (astAlgorithm) {
    case "header":
      return generateHeadersAst(diffOps);
    case "metadata":
      return generateMetadataAst(diffOps);
    default:
      return generateHeadersAst(diffOps);
  }
};
