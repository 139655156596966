import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Editor as MonacoEditor, useMonaco } from "@monaco-editor/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import type { IDisposable } from "monaco-editor";
import { CancellationToken, editor, languages } from "monaco-editor";
import styles from "./Editor.module.css";
import { SIGNADOT_DARK_THEME } from "./constants";
import { loadLanguageImplementations } from "./languages";
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;
import CodeLens = languages.CodeLens;

type Options = IStandaloneEditorConstructionOptions;

interface EditorProps {
  value?: string;
  defaultValue?: string;
  language: string;
  onChange?: (val: string | undefined) => void;
  options?: Options;
  header?: React.ReactNode;
  hideLineNumbers?: boolean;
  staticCodeLens?: CodeLens[];
}

const Editor: React.FC<EditorProps> = ({
  value,
  defaultValue,
  onChange,
  options,
  language,
  header,
  hideLineNumbers,
  staticCodeLens,
}) => {
  const languagesImplementation = useRef(loadLanguageImplementations(language));

  const editorRef = useRef<IStandaloneCodeEditor | null>(null);
  const monaco = useMonaco();

  const [calculatedTheme, setCalculatedTheme] = useState<{
    backgroundColor?: string;
    color?: string;
  }>({ backgroundColor: "white " });

  useEffect(() => {
    if (!monaco) return;

    let codeLensProvider: IDisposable | null = null;

    languagesImplementation.current.onMonacoAvailable?.(monaco);

    if (staticCodeLens && staticCodeLens?.length > 0) {
      codeLensProvider = monaco.languages.registerCodeLensProvider(language, {
        provideCodeLenses(): languages.ProviderResult<languages.CodeLensList> {
          return {
            lenses: staticCodeLens,
            dispose() {},
          };
        },
      });
    }

    return () => {
      if (codeLensProvider) {
        codeLensProvider.dispose();
      }
    };
  }, [monaco]);
  const handleEditorDidMount = (e: IStandaloneCodeEditor) => {
    editorRef.current = e;

    const domNode = e.getDomNode();
    if (domNode) {
      const { backgroundColor, color } = window.getComputedStyle(domNode);

      setCalculatedTheme({ backgroundColor, color });
    }

    e.updateOptions({ wordWrap: "on", minimap: { enabled: false } });

    if (hideLineNumbers) {
      e.updateOptions({ lineNumbers: "off" });
    }
  };

  return (
    <div className={styles.wrapper} style={{ ...calculatedTheme }}>
      <div className={styles.header}>{header}</div>

      <MonacoEditor
        className={styles.editor}
        defaultLanguage={language}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        theme={SIGNADOT_DARK_THEME}
        beforeMount={(m) => {
          languagesImplementation.current.beforeMount?.(m);
        }}
        onMount={handleEditorDidMount}
      />
    </div>
  );
};

export default Editor;
