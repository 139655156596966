import React from "react";
import { Card } from "@blueprintjs/core";
import type { TrafficDiffFinding } from "../../../../../../@types/sd/findings";
import style from "./Finding.module.css";
import DummyDiffViewer from "../../../common/DummyDiffViewer/DummyDiffViewer";
import CategoryIcon from "../../../common/CategoryIcon/CategoryIcon";

interface Props {
  finding: TrafficDiffFinding;
}

const Finding: React.FC<Props> = ({ finding }) => (
  <Card className={style.container}>
    <div className={style.header}>
      <div>
        <CategoryIcon category={finding.category} />
      </div>
      <div className={style.summary}>
        {finding.method} {finding.host}:{finding.port}
        {finding.baselinePath}
      </div>
    </div>
    <div className={style.detail}>
      <DummyDiffViewer diffs={finding.diffOps} />
    </div>
  </Card>
);

export default Finding;
