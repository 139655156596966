import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Button, ButtonGroup } from "@blueprintjs/core";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { DiffCat } from "../../../../../../@types/sd/findings.d.ts";
import style from "./CategoryFilter.module.css";

interface Props {
  initialFilters: DiffCat[];
  onChange: (value: DiffCat[]) => void;
}

const CategoryFilter: React.FC<Props> = ({ initialFilters, onChange }) => {
  const [filters, setFilters] = useState<DiffCat[]>(initialFilters);
  const highSelected = React.useMemo(
    () => filters.includes(DiffCat.Red),
    [filters]
  );
  const mediumSelected = React.useMemo(
    () => filters.includes(DiffCat.Yellow),
    [filters]
  );
  const lowSelected = React.useMemo(
    () => filters.includes(DiffCat.Green),
    [filters]
  );
  const toggleState = (value: DiffCat) => {
    setFilters((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((v) => v !== value);
      }
      return [...prevState, value];
    });
  };
  useEffect(() => {
    onChange(filters);
  }, [filters]);

  return (
    <div className={style.container}>
      <div>Relevance:</div>
      <ButtonGroup className={style.filters}>
        <Button
          className={classNames(style.filter, {
            [style.selected]: highSelected,
          })}
          onClick={() => toggleState(DiffCat.Red)}
        >
          High
        </Button>
        <Button
          className={classNames(style.filter, {
            [style.selected]: mediumSelected,
          })}
          onClick={() => toggleState(DiffCat.Yellow)}
        >
          Medium
        </Button>
        <Button
          className={classNames(style.filter, {
            [style.selected]: lowSelected,
          })}
          onClick={() => toggleState(DiffCat.Green)}
        >
          Low
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default CategoryFilter;
