// eslint-disable-next-line import/prefer-default-export
import type { Test } from "../../../@types/sd/testspec";

// eslint-disable-next-line import/prefer-default-export
export const filterTestSpecs = (
  filterText: string,
  items: Test[] = []
): Test[] =>
  items.filter((item) =>
    filterText
      .toLowerCase()
      .trim()
      .split(" ")
      .some(
        (token) =>
          item.name.toLowerCase().includes(token) ||
          (item.spec.triggers || []).some((service) =>
            service.sandboxOf.name.toLowerCase().includes(token)
          )
      )
  );
