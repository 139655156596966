/* eslint-disable react/no-array-index-key */

import React from "react";
import { Intent } from "@blueprintjs/core";
import Collapse from "../../../common/Collapse";
import type { CombinedTrafficDiffFinding } from "./util";
import { isFindingCollapseSectionVisible } from "./util";
import DiffViewer from "../../../common/DiffViewer/DiffViewer";
import styles from "./DataDisplay.module.css";

type DataDisplayProps = {
  type: "request" | "response";
  headers: CombinedTrafficDiffFinding[];
  body: CombinedTrafficDiffFinding[];
  metadata: CombinedTrafficDiffFinding[];
  baseKey: string;
};

const DataDisplay = ({
  type,
  headers,
  baseKey,
  body,
  metadata,
}: DataDisplayProps) => (
  <Collapse
    title={
      <div className={styles.mainHeader}>
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </div>
    }
    show={isFindingCollapseSectionVisible(headers, body, metadata)}
    tags={[
      {
        label: `${headers.length + body.length + metadata.length} findings`,
        intent: Intent.NONE,
      },
    ]}
    containerStyle={styles.containerStyleOverride}
  >
    <div className={styles.bodyWrapper}>
      <Collapse
        title="Headers"
        show={headers.length > 0}
        tags={[
          {
            label: `${headers.length} findings`,
            intent: Intent.NONE,
          },
        ]}
        defaultOpen={false}
        containerStyle={styles.partContainerStyleOverride}
        headerStyle={styles.partHeaderStyleOverride}
        bodyStyle={styles.partBodyStyleOverride}
      >
        <div className={styles.partBody}>
          {headers.map((f, index) => (
            <DiffViewer
              key={`${baseKey}-${type}-headers-${index}`}
              finding={f}
              diffOps={f[type].header}
              astAlgorithm="header"
            />
          ))}
        </div>
      </Collapse>

      <Collapse
        title="Body"
        show={body.length > 0}
        tags={[
          {
            label: `${body.length} findings`,
            intent: Intent.NONE,
          },
        ]}
        defaultOpen={false}
        containerStyle={styles.partContainerStyleOverride}
        headerStyle={styles.partHeaderStyleOverride}
        bodyStyle={styles.partBodyStyleOverride}
      >
        <div className={styles.partBody}>
          {body.map((f, index) => (
            <DiffViewer
              key={`${baseKey}-${type}-body-${index}`}
              finding={f}
              diffOps={f[type].body}
              astAlgorithm="body"
            />
          ))}
        </div>
      </Collapse>

      <Collapse
        title="Metadata"
        show={metadata.length > 0}
        tags={[
          {
            label: `${metadata.length} findings`,
            intent: Intent.NONE,
          },
        ]}
        defaultOpen={false}
        containerStyle={styles.partContainerStyleOverride}
        headerStyle={styles.partHeaderStyleOverride}
        bodyStyle={styles.partBodyStyleOverride}
      >
        <div className={styles.partBody}>
          {metadata.map((f, index) => (
            <DiffViewer
              key={`${baseKey}-${type}-metadata-${index}`}
              finding={f}
              diffOps={f[type].metadata}
              astAlgorithm="metadata"
            />
          ))}
        </div>
      </Collapse>
    </div>
  </Collapse>
);

export default DataDisplay;
