import type React from "react";
import type {
  Test,
  TestTrigger,
} from "../../../../@types/sd/testspec";

// eslint-disable-next-line import/prefer-default-export
export const testTriggerUpdater =
  (
    updateTestSpec: React.Dispatch<
      React.SetStateAction<Partial<Test> | undefined>
    >
  ) =>
  (
    idx: number,
    updater: ((currentTrigger: TestTrigger) => TestTrigger) | TestTrigger
  ) => {
    updateTestSpec((t: Partial<Test>) => {
      const triggers = t?.spec?.triggers;
      if (!triggers || triggers.length < idx) return { ...t };

      if (typeof updater === "function") {
        triggers[idx] = updater(triggers[idx]);
      } else {
        triggers[idx] = updater;
      }

      return { ...t, triggers: [...triggers] };
    });
  };
