import React from "react";
import { TbAlertOctagonFilled } from "react-icons/tb";
import { RiAlertFill } from "react-icons/ri";
import { IoInformationCircle } from "react-icons/io5";
import classNames from "classnames";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import style from "./CategoryIcon.module.css";

// @ts-ignore
// eslint-disable-next-line import/extensions
import { DiffCat } from "../../../../../@types/sd/findings.d.ts";

interface Props {
  category: DiffCat;
}

const iconSize = 20;

const CategoryIcon: React.FC<Props> = ({ category }) => {
  switch (category) {
    case DiffCat.Red:
      return (
        <Popover2>
          <Tooltip2
            content={<div>Relevance: High</div>}
            intent="none"
            hoverOpenDelay={250}
            position="top-left"
          >
            <TbAlertOctagonFilled
              className={classNames(style.icon, style.red)}
              size={iconSize}
            />
          </Tooltip2>
        </Popover2>
      );
    case DiffCat.Yellow:
      return (
        <Popover2>
          <Tooltip2
            content={<div>Relevance: Medium</div>}
            intent="none"
            hoverOpenDelay={250}
            position="top-left"
          >
            <RiAlertFill
              className={classNames(style.icon, style.yellow)}
              size={iconSize}
            />
          </Tooltip2>
        </Popover2>
      );
    case DiffCat.Green:
      return (
        <Popover2>
          <Tooltip2
            content={<div>Relevance: Low</div>}
            intent="none"
            hoverOpenDelay={250}
            position="top-left"
          >
            <IoInformationCircle
              className={classNames(style.icon, style.green)}
              size={iconSize}
            />
          </Tooltip2>
        </Popover2>
      );
    default:
      return null;
  }
};

export default CategoryIcon;
