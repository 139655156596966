import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ListExecutions.module.css";
import type { TestExecution } from "../../../../@types/sd/testexecutions";
import { useGetTestExecutions } from "../../../../api/TestExecutionsApi";
import type { FilteredViewColumn } from "../../../../components/FilteredView";
import FilteredView from "../../../../components/FilteredView";
import SdInput from "../../../../components/theming/SdInput";
import { filterTestExecutions } from "./utils";
import TimeInfo from "./table/TimeInfo";
import GeneralInfo from "./table/GeneralInfo";
import { CapturesInfo } from "./table";

interface Props {
  testName?: string;
}

export const COLUMNS: FilteredViewColumn<TestExecution>[] = [
  {
    id: "detail",
    render: (execution) => <GeneralInfo execution={execution} />,
    weight: 3,
  },
  {
    id: "duration__captures_info",
    render: (execution) => <CapturesInfo execution={execution} />,
    weight: 1,
  },
  {
    id: "duration__execution_time",
    render: (execution) => <TimeInfo execution={execution} />,
    weight: 1,
  },
];

const ListExecutions: React.FC<Props> = ({ testName }) => {
  const [filterText, setFilterText] = React.useState<string>("");
  const { isSuccess, isLoading, data } = useGetTestExecutions(testName || "", {
    enabled: testName !== undefined,
  });
  const navigate = useNavigate();
  const filteredList = React.useMemo(
    () => filterTestExecutions(data || [], filterText),
    [data, filterText]
  );
  if (!isSuccess || isLoading || !data) {
    return null;
  }
  return (
    <div key={testName} className={styles.container}>
      <div className={styles.title}>{testName}</div>
      <div className={styles.executionsTitle}>Executions</div>
      <SdInput
        name="search-field"
        type="text"
        placeholder="Type to search ..."
        className="bp4-input bp4-fill bp4-medium mb-2"
        onChange={(e) => setFilterText(e.target.value)}
      />
      <FilteredView
        title={(count) => `${count} test executions`}
        data={filteredList}
        filters={[]}
        columns={COLUMNS}
        totalWeight={5}
        pageSize={10}
        onRowClick={(r) =>
          navigate(`/testing/tests/${testName}/executions/${r.name}`)
        }
      />
    </div>
  );
};

export default ListExecutions;
