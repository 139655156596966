import React from "react";
import styles from "./CapturesInfo.module.css";
import type {
  TestExecution,
  TrafficDiff,
} from "../../../../../../@types/sd/testexecutions";

interface Props {
  execution: TestExecution;
}

const CapturesInfo: React.FC<Props> = ({ execution }) => {
  const { additions, removals, replacements } =
    execution.results?.trafficDiff || ({} as TrafficDiff);
  if (additions === 0 && removals === 0 && replacements === 0) {
    return null;
  }
  return (
    <div className={styles.items}>
      {additions > 0 && (
        <div className={styles.additions}>{additions} additions</div>
      )}
      {removals > 0 && (
        <div className={styles.removals}>{removals} removals</div>
      )}
      {replacements > 0 && (
        <div className={styles.replacements}>{replacements} replacements</div>
      )}
    </div>
  );
};

export default CapturesInfo;
