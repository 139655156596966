import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import type { TrafficDiffFinding } from "../../../../../@types/sd/findings";
import ServiceDetail from "./ServiceDetail/ServiceDetail";
import { groupFindingsByKey } from "./util";
import { useGetTest } from "../../../../../api/TestsApi";
import Loading from "../../../../../components/Loading/Loading";
import Error from "../../../../../components/Error/Error";

interface Props {
  findings: TrafficDiffFinding[];
  testName: string;
}

const GroupedView: React.FC<Props> = ({ findings, testName }) => {
  const navigate = useNavigate();

  const groupFindings = useMemo(
    () => groupFindingsByKey(findings, (f) => f.pairingID),
    [findings]
  );

  const sortedGroupFindings = useMemo(
    () =>
      Object.entries(groupFindings).sort(
        ([, f1], [, f2]) => f2.length - f1.length
      ),
    [findings, groupFindings]
  );

  const testDetail = useGetTest(testName);

  if (testDetail.isLoading) {
    return <Loading />;
  }

  if (testDetail.error) {
    // TODO: Distinguish between not found error and other errors for user messaging.
    // Requires an update to have the formattedSandboxData endpoint return a 404.
    if (testDetail.error.response?.status === 400) {
      // Response isn't set for timeouts, DNS resolution failures, server crash etc. Hence, the optional check above.
      navigate("/testing/tests");
      return null;
    }
    return (
      <Error
        text={`Error: ${
          testDetail.error.message || "Unknown error fetching the test"
        }`}
      />
    );
  }

  return (
    <div>
      {sortedGroupFindings.map(([pairingID, f]) => (
        <ServiceDetail
          key={pairingID}
          findings={f}
          testScript={testDetail?.data?.spec.script ?? ""}
        />
      ))}
    </div>
  );
};

export default GroupedView;
