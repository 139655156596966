import type { Dispatch, SetStateAction } from "react";
import React from "react";
import styles from "./Editor.module.css";
import TemplateParams from "./TemplateParams";
import { findPlaceholders } from "./TemplateParams/TemplateParams";
import BaseEditor from "../../../components/Editor";

interface EditorProps {
  text?: string;
  onChange: Dispatch<SetStateAction<string | undefined>>;
}

const Editor: React.FC<EditorProps> = ({ text, onChange }) => {
  const [template, setTemplate] = React.useState<string | undefined>(text);
  const [values, setValues] = React.useState<Record<string, string>>({});
  const [spec, setSpec] = React.useState<string | undefined>(text);
  const [displayFinalSpec, setDisplayFinalSpec] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    let temp = template;
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (value) {
        // Replace the placeholder with value, along with surrounding single/double quotes if exists.
        // TODO: This replaces the matching strings in the YAML comments as well which isn't great. Address this later.
        const replacePattern = `("@{${key}}")|('@{${key}}')|(@{${key}})`;
        temp = temp?.replace(new RegExp(replacePattern, "g"), value);
      }
    });
    setSpec(temp);
  }, [template, values]);
  React.useEffect(() => {
    onChange(spec);
  }, [spec]);
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.editor}>
          <BaseEditor
            language="yaml"
            value={displayFinalSpec ? spec : template}
            onChange={(val) => {
              if (!displayFinalSpec && findPlaceholders(val).size > 0) {
                setTemplate(val);
              }
            }}
            options={{
              readOnly: displayFinalSpec,
            }}
          />
        </div>
        <TemplateParams
          template={template}
          onValueUpdate={setValues}
          onDisplayFinalSpecChange={setDisplayFinalSpec}
        />
      </div>
    </div>
  );
};

export default Editor;
