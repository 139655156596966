import { HTMLSelect } from "@blueprintjs/core";
import React, { useEffect, useMemo } from "react";
import { useClustersApi } from "../../../../../../api/ClustersApi";

type ClusterSelectorProps = {
  value?: string;
  onChange: (clusterName: string) => void;
  unsetInitial?: boolean;
};

const ClusterSelector = ({
  value,
  onChange,
  unsetInitial,
}: ClusterSelectorProps) => {
  const clustersApi = useClustersApi();
  const clusterNames = useMemo(() => {
    if (!clustersApi.clusters) {
      return null;
    }

    return clustersApi.clusters.map((cluster) => ({
      name: cluster.name,
      id: cluster.id,
    }));
  }, [clustersApi.clusters]);

  useEffect(() => {
    if (unsetInitial) {
      return;
    }

    if (!value && clusterNames && clusterNames.length > 0) {
      onChange(clusterNames[0].name);
    }
  }, [clusterNames]);

  if (!clusterNames) {
    return <HTMLSelect />;
  }

  return (
    <HTMLSelect value={value} onChange={(item) => onChange(item.target.value)}>
      {unsetInitial && !value && <option value="@">Select a cluster</option>}

      {clusterNames.map((cluster) => (
        <option key={cluster.id} value={cluster.name}>
          {cluster.name}
        </option>
      ))}
    </HTMLSelect>
  );
};

export default ClusterSelector;
