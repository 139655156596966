// eslint-disable-next-line import/prefer-default-export
export const displayValue = (value: any): string => {
  if (value === null) {
    return "null";
  }
  if (value === undefined) {
    return "undefined";
  }
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  ) {
    return value.toString();
  }
  try {
    return JSON.stringify(value, null, 2);
  } catch (e) {
    return "Unable to display value";
  }
};
