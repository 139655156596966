import React, { useEffect, useState } from "react";
import { Button } from "@blueprintjs/core";
import { LuMinus, LuPlus } from "react-icons/lu";
import styles from "./ManagedRunnerGroups.module.css";

type ReplicasInputProps = {
  initialReplicas: number;
  onChange: (newCount: number) => void;
};

export const MINIMUM_REPLICAS_COUNT = 1;
const ReplicasInput = ({ initialReplicas, onChange }: ReplicasInputProps) => {
  const [replicas, setReplicas] = useState(initialReplicas);

  useEffect(() => {
    onChange(replicas);
  }, [replicas]);

  const increaseReplicas = () => {
    setReplicas((rep) => rep + 1);
  };

  const decreaseReplicas = () => {
    setReplicas((rep) => (rep === MINIMUM_REPLICAS_COUNT ? rep : rep - 1));
  };

  return (
    <div className={styles.replicasInput}>
      <Button onClick={decreaseReplicas} disabled={replicas === 1}>
        <LuMinus />
      </Button>
      <div>
        <p>{replicas}</p>
      </div>
      <Button onClick={increaseReplicas}>
        <LuPlus />
      </Button>
    </div>
  );
};

export default ReplicasInput;
