import type { Monaco } from "@monaco-editor/react";

// eslint-disable-next-line import/prefer-default-export
export const getTestsHttpMethodsSuggestions = (monaco: Monaco) => [
  {
    label: "http.get",
    kind: monaco.languages.CompletionItemKind.Function,
    insertText: "http.get()",
    insertTextRules:
      monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    documentation: "Perform an HTTP GET request",
  },
  {
    label: "http.post",
    kind: monaco.languages.CompletionItemKind.Function,
    // eslint-disable-next-line no-template-curly-in-string
    insertText: "http.post(${1:url}, capture=${2:True|False})",
    insertTextRules:
      monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    documentation: `
Sends a POST request to the specified URL.

Parameters:
-----------
url : str
    The URL to which the POST request will be sent. It must be a valid URL string.

capture : bool
    A flag that indicates whether to automatically handle differences in responses (e.g., for API versioning, response format changes, etc.).
    - If \`True\`, the method will automatically handle and process any differences in response data.
    - If \`False\`, the response will be treated without any additional handling or modification.
`,
  },
  {
    label: "http.delete",
    kind: monaco.languages.CompletionItemKind.Function,
    insertText: "http.delete()",
    insertTextRules:
      monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    documentation: "Perform an HTTP DELETE request",
  },
];
