import React from "react";
import styles from "./Tests.module.css";
import ListTests from "./ListTests";
import ListExecutions from "./ListExecutions";

const Tests = () => {
  const [selectedTest, setSelectedTest] = React.useState<string | undefined>(
    undefined
  );
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <ListTests onSelected={(t) => setSelectedTest(t)} />
      </div>
      <div className={styles.right}>
        <ListExecutions testName={selectedTest} />
      </div>
    </div>
  );
};

export default Tests;
