import type { Monaco } from "@monaco-editor/react";
import { getTestsHttpMethodsSuggestions } from "./suggestions";

// eslint-disable-next-line import/prefer-default-export
export const setTestAutocomplete = (monaco: Monaco) => {
  monaco.languages.registerCompletionItemProvider("python", {
    provideCompletionItems: (model, position) => {
      // Get the text before the current position
      const textUntilPosition = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: Math.max(1, position.column - 10),
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });

      const testsSuggestions = getTestsHttpMethodsSuggestions(monaco);

      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      };

      const suggestions = testsSuggestions
        .filter(
          (testSuggestion) =>
            textUntilPosition
              .toLowerCase()
              .includes(testSuggestion.label.toLowerCase().split(".")[1]) // Match by method name, e.g., 'get', 'post', etc.
        )
        .map((suggestion) => ({
          ...suggestion,
          range,
        }));

      // If text matches general patterns like "h", "ht", "ttp", suggest all
      if (/h|ht|ttp/i.test(textUntilPosition)) {
        testsSuggestions.forEach((method) =>
          suggestions.push({ ...method, range })
        );
      }

      return {
        suggestions: suggestions,
      };
    },
  });
};
