import { ControlGroup, HTMLSelect, InputGroup } from "@blueprintjs/core";
import React from "react";
import ClusterSelector from "../ClusterSelector";
import type { TestTrigger } from "../../../../../../@types/sd/testspec";

type TriggerInputProps = {
  trigger: TestTrigger;
  onChange: (trigger: TestTrigger) => void;
};

const TriggerInput = ({ trigger, onChange }: TriggerInputProps) => (
  <ControlGroup vertical fill>
    <ClusterSelector
      value={trigger?.executionTemplate?.cluster}
      onChange={(clusterName) =>
        onChange({
          ...trigger,
          executionTemplate: {
            ...trigger.executionTemplate,
            cluster: clusterName,
          },
        })
      }
    />
    <div style={{ minHeight: "0.5rem" }} />

    <HTMLSelect
      value={trigger?.sandboxOf?.kind}
      onChange={(item) => {
        onChange({
          ...trigger,
          sandboxOf: {
            ...trigger.sandboxOf,
            kind: item.target.value,
          },
        });
      }}
    >
      <option value="Deployment">Deployment</option>
      <option value="Service">Service</option>
      <option value="Rollout">Rollout</option>
    </HTMLSelect>

    <div style={{ minHeight: "0.5rem" }} />

    <InputGroup
      placeholder="Service Name"
      fill
      value={trigger?.sandboxOf?.name}
      onChange={(event) => {
        onChange({
          ...trigger,
          sandboxOf: {
            ...trigger.sandboxOf,
            name: event.target.value,
          },
        });
      }}
    />

    <div style={{ minHeight: "0.5rem" }} />

    <InputGroup
      placeholder="Namespace"
      fill
      value={trigger?.sandboxOf?.namespace}
      onChange={(event) => {
        onChange({
          ...trigger,
          sandboxOf: {
            ...trigger.sandboxOf,
            namespace: event.target.value,
          },
        });
      }}
    />
  </ControlGroup>
);

export default TriggerInput;
