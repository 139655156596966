import React, { useRef, useState } from "react";
import type { Intent } from "@blueprintjs/core";
import classNames from "classnames";
import styles from "./Collapse.module.css";

type CollapseTag = {
  label: string;
  intent: Intent;
};

type CollapseProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  show: boolean;
  tags?: CollapseTag[];
  children?: React.ReactNode;
  containerStyle?: string;
  headerStyle?: string;
  bodyStyle?: string;
  defaultOpen?: boolean;
};

const Collapse = ({
  children,
  title,
  description,
  show,
  tags,
  containerStyle,
  headerStyle,
  bodyStyle,
  defaultOpen = true,
}: CollapseProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [containerStyle!]: !!containerStyle,
      })}
    >
      <div
        className={classNames(styles.header, { [headerStyle!]: !!headerStyle })}
      >
        <h4 className={styles.title}>{title}</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          {tags &&
            tags.map((tag) => (
              <span className={styles.tag} key={tag.label}>
                {tag.label}
              </span>
            ))}

          <button onClick={toggleCollapsible} className={styles.button}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={styles.icon}
              style={{
                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      {description}
      <div
        ref={contentRef}
        className={classNames(styles.collapsible, {
          [bodyStyle!]: isOpen && !!bodyStyle,
        })}
        style={{
          maxHeight: isOpen ? "fit-content" : "0",
        }}
      >
        <div className={styles.collapsibleContent}>{children}</div>
      </div>
    </div>
  );
};

export default Collapse;
