import type { ApiError } from "../hooks/UseApi";
import useApi from "../hooks/UseApi";
import useApiMutation from "../hooks/UseApiMutation";

export const GET_MANAGED_RUNNER_GROUPS_API_NAME =
  "api:get-managed-runner-groups";
export const SAVE_MANAGED_RUNNER_GROUPS_API_NAME =
  "api:save-managed-runner-groups";

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 5000,
};

export interface ManagedRGClusterSettings {
  replicas: number;
  eligible: boolean;
  notes?: string[];
}

export interface ManagedRGData {
  enabled: boolean;
  clusters: Record<string, ManagedRGClusterSettings>;
}

export const useManagedRunnerGroups = () =>
  useApi<ManagedRGData>(
    [GET_MANAGED_RUNNER_GROUPS_API_NAME],
    `/api/v2/orgs/:orgName/settings/managed-jobrunnergroups`,
    {
      ...standardQueryConfig,
    }
  );

export const useSaveManagedRGSettings = (
  onSaved: () => void,
  onError: (error: ApiError) => void
) =>
  useApiMutation<unknown, ManagedRGData>(
    SAVE_MANAGED_RUNNER_GROUPS_API_NAME,
    "POST",
    [GET_MANAGED_RUNNER_GROUPS_API_NAME],
    onSaved,
    onError
  );
