import React from "react";
import { Tag } from "@blueprintjs/core";
import styles from "./ExecutionStatus.module.css";
import type { TestExecutionStatus } from "../../../../../@types/sd/testexecutions";

type ExecutionState = TestExecutionStatus;

interface ExecutionStatusProps {
  status: ExecutionState;
}

// Not too great an approach, but it works for now.
const executionColorMapping: Record<ExecutionState, string> = {
  canceled: styles.canceled,
  failed: styles.failed,
  succeeded: styles.succeeded,
  in_progress: styles.running,
  pending: styles.queued,
};

const ExecutionStatus: React.FC<ExecutionStatusProps> = ({ status }) => (
  <Tag round className={`${styles.icon} ${executionColorMapping[status]}`}>
    {status}
  </Tag>
);

export default ExecutionStatus;
