import React, { useMemo } from "react";
import type {
  FindingDiffOp,
  TrafficDiffFinding,
} from "../../../../../@types/sd/findings";
import style from "./DiffViewer.module.css";
import type { AstAlgorithm } from "./ast";
import { generateAstDiff } from "./ast";
import { KeywordRenderer } from "../FindingElement/FindingElement";
import CategoryIcon from "../CategoryIcon/CategoryIcon";

type DiffViewerProps = {
  finding: TrafficDiffFinding;
  diffOps: FindingDiffOp[];
  astAlgorithm: AstAlgorithm;
};

const DiffViewer: React.FC<DiffViewerProps> = ({
  finding,
  diffOps,
  astAlgorithm,
}) => {
  const astKeywords = useMemo(
    () => generateAstDiff(diffOps, astAlgorithm),
    [diffOps]
  );

  return (
    <div className={style.container}>
      <div className={style.relevance}>
        <div>Relevance:</div>
        <CategoryIcon category={finding.category} />
      </div>
      <KeywordRenderer keywords={astKeywords} />
    </div>
  );
};

export default DiffViewer;
