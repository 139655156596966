import React from "react";
import { Tooltip2 } from "@blueprintjs/popover2";
import type { IconProps } from "@blueprintjs/core";
import { Icon, Tag } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/icons";
import type { TestExecution } from "../../../../../../@types/sd/testexecutions";
import styles from "./GeneralInfo.module.css";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { TestExecutionStatus } from "../../../../../../@types/sd/testexecutions.d.ts";
import { getRoutingContext, runPrefix } from "./util";

export const ICON_MAPPING: Record<string, IconName> = {
  [TestExecutionStatus.Pending]: "time",
  [TestExecutionStatus.InProgress]: "symbol-circle",
  [TestExecutionStatus.Failed]: "cross-circle",
  [TestExecutionStatus.Succeeded]: "tick-circle",
  [TestExecutionStatus.Canceled]: "ban-circle",
};

const statusColorMap = {
  [TestExecutionStatus.Pending]: styles.pending,
  [TestExecutionStatus.InProgress]: styles.inProgress,
  [TestExecutionStatus.Failed]: styles.failed,
  [TestExecutionStatus.Succeeded]: styles.succeeded,
  [TestExecutionStatus.Canceled]: styles.canceled,
};

const getIconStyles = (execution: TestExecution): IconProps => ({
  icon: ICON_MAPPING[execution.status.phase],
  size: 16,
  className: statusColorMap[execution.status.phase],
});

interface Props {
  execution: TestExecution;
}

const GeneralInfo: React.FC<Props> = ({ execution }) => {
  const routingContext = getRoutingContext(execution);
  const triggeredBy = execution.status.triggeredBy;
  return (
    <div className={styles.detail}>
      <Tooltip2 content={execution.status.phase}>
        <Icon {...getIconStyles(execution)} />
      </Tooltip2>
      <div className={styles.body}>
        <div className={styles.title}>{execution.name}</div>
        {triggeredBy && (
          <div className={styles.triggerInfo}>
            <div>Triggered by:</div>
            {triggeredBy?.sandbox && (
              <Tag className={styles.tag}>sandbox: {triggeredBy.sandbox}</Tag>
            )}
            {triggeredBy?.trigger && (
              <Tag className={styles.tag}>
                workload: {triggeredBy.trigger.sandboxOf.name}
              </Tag>
            )}
          </div>
        )}
        <div className={styles.routingContext}>
          {runPrefix(execution)} against the environment:{" "}
          <span className={styles.environment}>{routingContext.value}</span>{" "}
          <span>
            {routingContext.value === ""
              ? `${routingContext.context}`
              : `(${routingContext.context})`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
